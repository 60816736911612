import React from 'react';
import Page from '../components/Page';
import { graphql } from 'gatsby';
import IStrapiCookiesPage from '../interfaces/IStrapiCookiesPage';

interface Props {
  data: {
    strapiCookiesPage: IStrapiCookiesPage;
  };
}

const CookiesPage = ({ data }: Props) => {
  const { content, heroImage, heroImageMobile, seo } = data?.strapiCookiesPage || {};
  console.log(content, heroImage, heroImageMobile, seo);
  return (
    <Page
      desktopImage={heroImage.localFile}
      mobileImage={heroImageMobile.localFile}
      imageAlt={heroImage.alternativeText}
      title={'Cookies'}
      content={content}
      seoTitle={seo.title}
      seoDesc={seo.description}
      seoUrl={'/cookies/'}
      seoImageUrl={seo.image?.localFile.publicURL || ''}
    />
  );
};

export default CookiesPage;

export const pageQuery = graphql`
  query {
    strapiCookiesPage {
      content
      seo {
        title
        description
      }
      heroImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 800
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
      heroImageMobile {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [750, 1200]
              width: 800
              quality: 70
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`;
